<template>
    <div>
        <header id="fx-header">
            <!-- 一级头部 -->
            <div id="ts-head">
                <div class="litem n-list" :class="{'active':wNav==index}" v-for="(m, index) in wNavList" @click.stop="setWNav(index)">
                    <p class="name">{{m.title}}</p>
                </div>
                <i class="hwiconfont icon-sousuo" @click.stop="to_url('h5search')"></i>
            </div>
            <!-- 二级头部 -->
            <div class="nav-box" v-if="wNav==1">
                <ul class="nav-ul">
                    <li class="list" @click.stop="setCate(index)" :class="{'active':cate==index}" v-for="(m, index) in cateList">{{m.name}}</li>
                </ul>
                <!-- <i class="hwiconfont icon-caidan1 icon-pad" @click.stop="openNavEdit()"></i> -->
            </div>
        </header>
        <dyList :list="list" h5="1" v-infinite-scroll="getData"></dyList>
        <p class="more-btn" v-if="list.length">{{total==list.length ? '暂无更多':'加载中'}}</p>
    </div>
</template>
<script>
    import {
        mapGetters,
        mapMutations
    } from 'vuex';
    import dyList from "@components/common/dy-list";
    export default {
        components: {
            dyList,
        },
        data() {
            return {
                wNav: 1,
                wNavList: [{
                    title: '关注'
                }, {
                    title: '推荐'
                }],
                cateList: [],
                cate: 0,
                list: [],
                total: 0,
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
        },
        mounted() {
            // 在组件被挂载之后调用。
            let cate = this.$y_getKey("cate");
            if (cate) {
                cate.unshift({
                    name: "推荐",
                    id: 0
                });
                this.cateList = cate;
            }
            this.getData(1);
        },
        methods: {
            // ...mapMutations([])
            // 栏目编辑
            openNavEdit() {},
            // 一级栏目切换
            setWNav(i) {
                this.wNav = i;
                this.list = [];
                this.getData(1);
            },
            // 二级栏目切换
            setCate(i) {
                this.cate = i;
                document.querySelector("html").scrollTop = 0;
                this.list = [];
                this.getData(1);
            },
            // 获取列表
            async getData(first) {
                let param = {
                    longitude: this.$y_getKey("lon"),
                    latitude: this.$y_getKey("lat"),
                    type: 0, // 动态类型
                    user_id: this.$y_getKey("userid") || 8,
                };
                if (this.wNav == 0) {
                    param.following = 1
                } else {
                    param.category_id = this.cateList[this.cate].id
                }
                if (first) {
                    param.page = 1;
                }
                let {
                    list,
                    total
                } = await this.$y_list("api/dymanic/lists", this.list, param);
                this.list = list;
                this.total = total;
            },
        },
        computed: {
            // 计算属性
            // ...mapGetters([])
        },
        watch: {
            // 监听
        }
    };
</script>

<style lang="less" scoped>
    #fx-header {
        background: #fff;
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        left: 0;
        width: 100vw;
        box-sizing: border-box;
        z-index: 1;
    }

    #ts-head {
        display: flex;
        align-items: center;
        justify-content: center;

        .n-list {
            position: relative;
            padding: 10px;

            .name {
                color: #aaa;
                font-size: 17px;
            }

            &::after {
                display: inline-block;
                content: '';
                height: 7px;
                width: 30px;
                margin-top: -5px;
                position: absolute;
                border-radius: 5px;
            }

            &.active {
                .name {
                    font-size: 17px;
                    font-weight: bold;
                    color: #333;
                }

                &::after {
                    background: linear-gradient(to right, #fd5621, rgba(255, 255, 255, 0));
                }
            }



        }

        .icon-sousuo {
            font-size: 23px;
            border: 10px solid transparent;
            position: absolute;
            right: 0;
        }



    }

    .nav-box {
        display: flex;
        align-items: center;
        position: relative;

        .nav-ul {
            flex: 1;
            display: -webkit-box;
            overflow-x: auto;

            .list {
                color: #aaa;
                padding: 5px 10px;

                &.active {
                    color: #fd5621;
                }
            }
        }

        .icon-caidan1 {
            padding: 10px;
        }
    }
</style>